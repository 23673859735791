import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import { theme } from '../../../../components/theme';

const Theme = theme as { [key: string]: any };

export const IndividualItemS = styled.div<{
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: string;
}>`
  display: flex;
  border: 1px solid ${Theme.mediumLightGray};
  border-radius: 8px;
  padding: ${({ padding }) => padding || '16px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
  align-items: ${({ alignItems }) => alignItems || ''};
  width: 100%;
  cursor: pointer;

  .MuiTypography-root {
    font-family: Cabin, sans-serif;
  }

  .info-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    font-family: aesthet-nova, sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const AnimalCharacteristics = styled(Typography)`
  &.MuiTypography-root {
    color: ${Theme.secondGray};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const SIconInfo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 8px;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    color: ${Theme.secondGray};
  }
`;

export const InfoWrapper = styled.div`
  width: 50%;
  padding: 8px;
`;

export const InfoField = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '50%'};
  margin-bottom: 4px;
`;
