import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPropertyUrl } from 'data/urls/property.url';
import { propertySearchConverter, unitSearchConverter } from 'data/converters/property.converters';
import { TPropertySearchDB, TPropertySearchUI, TUnitSearchDB, TUnitSearchUI } from 'data/types/property.types';

const baseUrl = `${EPropertyUrl.PROPERTIES}`;

export const propertyApi = authSplitApi('property', ['properties', 'units'], true).injectEndpoints({
  endpoints: (build) => ({
    getPropertiesSearch: build.query<TPropertySearchUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EPropertyUrl.SEARCH}`,
          method: 'GET',
        };
      },
      providesTags: ['properties'],
      transformResponse: (data: TPropertySearchDB[]) => data.map((item) => propertySearchConverter.fromDb(item)),
    }),
    getUnitsSearch: build.query<TUnitSearchUI[], string>({
      query(propertyId) {
        return {
          url: `${baseUrl}/${propertyId}/${EPropertyUrl.UNITS}/${EPropertyUrl.SEARCH}`,
          method: 'GET',
        };
      },
      providesTags: ['units'],
      transformResponse: (data: TUnitSearchDB[]) => data.map((item) => unitSearchConverter.fromDb(item)),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPropertiesSearchQuery, useGetUnitsSearchQuery } = propertyApi;
