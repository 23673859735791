import React from 'react';
import { AutoComplete } from '@landlord-tech/opp-ui-kit';
import { TextareaAutosize } from '@mui/material';
import { EComplaintTitleUI } from 'data/types/complaint.types';
import { ErrorMessage, FormBlock, FormFields } from '../../styles';
import { MediumLightGrayText } from '../../../style';
import { AddCommentLabel, TextAreaField } from '../../../../../pages/AnimalComplaint/components/AddComment/styles';
import { TComplaintInformationProps } from './ComplaintInformation.types';

const complaintTypes = [
  {
    key: EComplaintTitleUI.UNAUTHORIZED_ANIMAL,
    name: EComplaintTitleUI.UNAUTHORIZED_ANIMAL,
  },
  {
    key: EComplaintTitleUI.DAMAGE,
    name: EComplaintTitleUI.DAMAGE,
  },
  {
    key: EComplaintTitleUI.NOISE_DISTURBANCE,
    name: EComplaintTitleUI.NOISE_DISTURBANCE,
  },
  {
    key: EComplaintTitleUI.WASTE_MANAGEMENT,
    name: EComplaintTitleUI.WASTE_MANAGEMENT,
  },
  {
    key: EComplaintTitleUI.PET_RULE_VIOLATION,
    name: EComplaintTitleUI.PET_RULE_VIOLATION,
  },
  {
    key: EComplaintTitleUI.AGGRESSIVE_BEHAVIOR,
    name: EComplaintTitleUI.AGGRESSIVE_BEHAVIOR,
  },
  {
    key: EComplaintTitleUI.OTHER,
    name: EComplaintTitleUI.OTHER,
  },
];

const ComplaintInformation = ({ value, handleChange, handleBlur, errors, touched }: TComplaintInformationProps) => {
  return (
    <FormBlock>
      <MediumLightGrayText>Complaint information</MediumLightGrayText>
      <FormFields>
        <AutoComplete
          name='title'
          label='Type of complaint'
          handleChange={(name: string, value: any) => handleChange(name, value[0].key)}
          value={value ? value.toString() : ''}
          multiple={false}
          errorMessage={(touched.title && errors.title) as string}
          placeholder='Complaint Type'
          options={complaintTypes}
          handleBlur={handleBlur}
        />
      </FormFields>
      <TextAreaField>
        <AddCommentLabel>Message</AddCommentLabel>
        <TextareaAutosize
          minRows={5}
          maxRows={7}
          name='description'
          onChange={(e) => handleChange('description', e.target.value)}
          aria-label='maximum height'
          placeholder='Type something'
          defaultValue=''
        />
        {touched.description && errors.description && (
          <ErrorMessage className='error-autoComplete' component='p' variant='body1'>
            {errors.description}
          </ErrorMessage>
        )}
      </TextAreaField>
    </FormBlock>
  );
};

export default ComplaintInformation;
