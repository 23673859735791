import React from 'react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { TAnimalUI } from 'data/types/animal.types';

import IndividualInfo from '../../../IndividualInfo';
import { AnimalTitle, Flex } from '../../../../style';
import { IndividualInfoBlockLarge, InfoTitle } from '../../../../../../components/Base/style';

const dateFormat = 'MM/DD/YYYY';

const getAge = (age: number, ageUnit: string) => {
  if (!age) {
    return null;
  }

  if (ageUnit === 'YEARS') {
    return `${age} years`;
  }
  if (age < 12) {
    return `${age} months`;
  }
  return `${Math.floor(age / 12)} years ${age % 12} months`;
};

const AnimalInfo = ({
  data: { type, color, breed, gender, age, ageUnit, weight, expirationDate, houseTrained },
}: {
  data: TAnimalUI;
}) => {
  return (
    <>
      <AnimalTitle component='h2' variant='h2'>
        Animal info
      </AnimalTitle>
      <Flex>
        <IndividualInfo label='Type' value={type} />
        <IndividualInfo label='Color' value={color?.join(', ')} width='45%' />
      </Flex>
      <Flex>
        <IndividualInfo label='Breed' value={breed} />
        <IndividualInfo label='Gender' value={gender} width='45%' />
      </Flex>
      <Flex>
        <IndividualInfo label='Age' value={getAge(age, ageUnit) || ''} />
        <IndividualInfo label='Weight (lbs)' value={weight} width='45%' />
      </Flex>
      <IndividualInfoBlockLarge>
        <InfoTitle>Rabies vaccination expiration date</InfoTitle>
        <Typography component='h5' variant='h5'>
          {expirationDate ? moment(expirationDate, 'YYYY-MM-DD').format(dateFormat) : 'N/A'}
        </Typography>
      </IndividualInfoBlockLarge>
      <IndividualInfoBlockLarge>
        <InfoTitle>Is the animal house trained?</InfoTitle>
        <Typography component='h5' variant='h5'>
          {houseTrained}
        </Typography>
      </IndividualInfoBlockLarge>
    </>
  );
};

export default AnimalInfo;
