import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { theme } from 'components/theme';

export const SSearch = styled('div')`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const SIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SSearchIcon = styled(IconButton)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    path {
      fill: ${theme.lightBlack};
    }
  }
`;

export const STextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 30px;
    background-color: ${theme.mediumLightGray};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & input {
    padding: 12px 56px;
  }
`;

export const Loading = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: black;
  z-index: 1600;
  display: ${({ hidden }) => (!hidden ? `flex` : `none`)};
  align-items: center;
  justify-content: center;
`;
