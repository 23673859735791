import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EComplaintUrl } from 'data/urls/complaint.url';
import { TCreateComplaintUI } from 'data/types/complaint.types';
import { createComplaintConverter } from 'data/converters/complaint.converters';
import { TComplaint, TComplaintData, TComplaintFromDb } from 'types/types/Complaint';
import { simpleComplaintConverter, simpleComplaintsConverter } from 'convertors/complaint.converter';

const URL = EComplaintUrl.COMPLAINTS;

export const ComplaintApi = authSplitApi('complaint', ['Complaint', 'Complaints'], true).injectEndpoints({
  endpoints: (build) => ({
    getComplaint: build.query<TComplaintData, { complaintId: string; userId: string }>({
      query({ complaintId, ...params }) {
        return {
          url: `${URL}/${complaintId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Complaint'],
      transformResponse: (data: TComplaintFromDb) => {
        return simpleComplaintConverter(data);
      },
    }),
    getComplaints: build.query<TComplaint[], { maintainerId: string; unitId: string }>({
      query(params) {
        return {
          url: `${URL}/all`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Complaints'],
      transformResponse: (data: TComplaintFromDb[]) => {
        return simpleComplaintsConverter(data);
      },
    }),
    createComplaint: build.mutation<void, TCreateComplaintUI>({
      query(body) {
        return {
          url: URL,
          method: 'POST',
          body: createComplaintConverter.toDb(body),
        };
      },
      invalidatesTags: ['Complaints'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetComplaintQuery, useGetComplaintsQuery, useCreateComplaintMutation } = ComplaintApi;
