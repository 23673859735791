import React, { FC, useEffect, useState } from 'react';
import { Button } from '@landlord-tech/opp-ui-kit';
import Modal from 'components/Base/Modal';
import { useAppSelector } from 'redux/store';
import { UseForm } from 'components/useForm';
import { useAppDispatch } from 'contexts/store';
import { EditBtns } from 'pages/ProfileEdit/styles';
import { useCreateNoticeMutation } from 'redux/apiSlice/notice.slices';
import { useCreateComplaintMutation } from 'redux/apiSlice/complaint.slices';
import { TFileUI } from 'data/types/general.types';
import { EComplaintTypeUI } from 'data/types/complaint.types';
import FormContent from './components/FormContent';
import PredefinedInfo from './components/PredefinedInfo';
import { validationSchema } from './validationSchema';
import { TFormValues, TReportAnimalComplaintProps } from './ReportAnimalComplaint.types';
import { ReportAnimalComplaintS, ReportAnimalComplaintBtn } from './styles';

const ReportAnimalComplaint: FC<TReportAnimalComplaintProps> = ({ animal }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<TFileUI[] | []>([]);
  const profile = useAppSelector((state) => state.authSlice.user);
  const { rentalId, rentalUnitNumber, tenantName, residenceId } = animal || {};
  const { communicateWithResidents } = profile || {};

  const [createComplaint, { isLoading: loadingComplaint }] = useCreateComplaintMutation();
  const [createNotice, { isLoading: loadingNotice }] = useCreateNoticeMutation();

  const handleSubmit = async (val: TFormValues) => {
    try {
      if (communicateWithResidents) {
        await createComplaint({ ...val, type: EComplaintTypeUI.COMPLAINT, attachments: files }).unwrap();
      } else {
        await createNotice({ ...val, attachments: files }).unwrap();
      }
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'success',
          message: 'Complaint successfully submitted',
        },
      });
      setOpen(false);
    } catch (e) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'error',
          message: 'Something went wrong',
        },
      });
    }
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    setErrors,
    handleSubmit: handleFormSubmit,
    setFieldValue,
  } = UseForm({
    initialValues: {
      propertyId: rentalId || '',
      unitName: rentalUnitNumber || '',
      residenceId: residenceId || '',
      description: '',
      title: '',
      violatorName: tenantName || '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
  });

  useEffect(() => {
    if (values.propertyId && errors.propertyId) {
      setErrors({
        ...errors,
        propertyId: '',
      });
    } else if (values.title && errors.title) {
      setErrors({
        ...errors,
        title: '',
      });
    }
  }, [errors, setErrors, values]);

  return (
    <>
      <ReportAnimalComplaintBtn>
        <Button onClick={() => setOpen(true)} text='Report an animal complaint' />
      </ReportAnimalComplaintBtn>
      <Modal
        title='Create a complaint'
        className='report-animal-complaint'
        open={open}
        handleClose={() => setOpen(false)}
      >
        <ReportAnimalComplaintS>
          {animal && <PredefinedInfo data={animal} />}
          <form id='save-new-complaint' onSubmit={handleFormSubmit}>
            <FormContent
              errors={errors}
              touched={touched}
              values={values}
              handleBlur={handleBlur}
              handleChange={setFieldValue}
              files={files}
              onFilesChange={setFiles}
              hideAddressInfo={!!animal}
            />
          </form>
        </ReportAnimalComplaintS>
        <EditBtns justify='center' className='edit-btn'>
          <Button
            form='save-new-complaint'
            className='submit-complaint'
            type='submit'
            text='Submit animal complaint'
            disabled={loadingComplaint || loadingNotice}
          />
        </EditBtns>
      </Modal>
    </>
  );
};

export default ReportAnimalComplaint;
