import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TNameUpdateDB,
  TNameUpdateUI,
  TPhotoUpdateDB,
  TPhotoUpdateUI,
  TProfileDB,
  TProfileUI,
} from 'data/types/profile.types';

export const profileGetConverter: TFetchConverter<TProfileUI, TProfileDB> = {
  fromDb: (data) => ({
    id: data.maintainerId,
    fullName: data.fullName,
    phone: data.phone,
    photoUrl: data.photoUrl,
    communicateWithResidents: data.communicateWithResidents,
    properties: data.properties,
    showIntro: data.showIntro,
  }),
};

export const photoUpdateConverter: TCreateConverter<TPhotoUpdateUI, TPhotoUpdateDB> = {
  toDb: (data) => ({
    photoUrl: data.photoUrl,
  }),
};

export const nameUpdateConverter: TCreateConverter<TNameUpdateUI, TNameUpdateDB> = {
  toDb: (data) => ({
    fullName: data.fullName,
  }),
};
