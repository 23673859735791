import React from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from 'assets/images/uploadImage.svg';
import { useAppDispatch } from 'contexts/store';
import { useUploadFileMutation } from 'redux/apiSlice/storage.slice';
import { EFileTypeUI, TFileUI } from 'data/types/general.types';
import { fileTypesMapper, uploadItemByFileType } from './data';
import { TUploaderProps } from './types';
import { ThumbContainer, DropInputWrapperEmpty, StyledErrorMessage, Label, DragText, SWrapper } from './styles';

const PhotoDropzone = ({
  files = [],
  onChange = () => {
    /* do nothing */
  },
  className,
  label,
  error = '',
  disabled,
}: TUploaderProps) => {
  const dispatch = useAppDispatch();

  const [uploadFile] = useUploadFileMutation();

  const onUploadFile = async (acceptedFiles: File[]) => {
    const acceptedFilesNew: { [p: string]: EFileTypeUI } = {};
    acceptedFiles.forEach((acceptedFile) => {
      let type = EFileTypeUI.OTHER;
      const findType = Object.keys(fileTypesMapper)?.find((item) => {
        const key = item as EFileTypeUI;
        return fileTypesMapper[key].indexOf(acceptedFile.type as EFileTypeUI) !== -1;
      });
      if (findType) {
        type = findType as EFileTypeUI;
      }
      acceptedFilesNew[acceptedFile.name] = type;
    });

    const responses = acceptedFiles.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      return uploadFile(formData).unwrap();
    });

    Promise.all(responses)
      .then((data) => {
        const newData = data.map((item) => {
          const key = item.name as string;
          return { ...item, type: acceptedFilesNew[key] };
        });
        onChange([...files, ...newData]);
      })
      .catch(() => {
        dispatch({
          type: 'OPEN_ALERT',
          payload: {
            severity: 'error',
            message: 'Unable to upload file',
          },
        });
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: [...fileTypesMapper.image, ...fileTypesMapper.video],
    multiple: true,
    onDropRejected: () => {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: 'Unsupported image format',
          severity: 'error',
        },
      });
    },
    onDropAccepted: onUploadFile,
  });

  const onDelete = (file: TFileUI) => {
    const updatedFiles = files.filter((item: TFileUI) => item.name !== file.name);
    onChange(updatedFiles);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Label>{label}</Label>
      <ThumbContainer>
        {files.map((file) => {
          const Component = uploadItemByFileType[file.type];
          return <Component file={file} onDelete={() => onDelete(file)} readOnly={false} key={file.name} />;
        })}
      </ThumbContainer>
      <DropInputWrapperEmpty {...getRootProps()}>
        <input {...getInputProps()} disabled={disabled} />
        <SWrapper>
          <img src={uploadIcon} alt='Upload' />
          <DragText>Select the documents from Gallery</DragText>
        </SWrapper>
      </DropInputWrapperEmpty>
      {error ? <StyledErrorMessage>{error}</StyledErrorMessage> : null}
    </div>
  );
};

export default PhotoDropzone;
