import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EResidencyUrl } from 'data/urls/residency.url';
import { residencySearchConverter } from 'data/converters/residnecy.converters';
import { TResidencySearchDB, TResidencySearchParams, TResidencySearchUI } from 'data/types/residency.types';

const baseUrl = `${EResidencyUrl.RESIDENCIES}`;

export const residencyApi = authSplitApi('residency', ['residencies'], true).injectEndpoints({
  endpoints: (build) => ({
    getResidenciesSearch: build.query<TResidencySearchUI[], TResidencySearchParams>({
      query(params) {
        return {
          url: `${baseUrl}/${EResidencyUrl.SEARCH}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['profile'],
      transformResponse: (data: TResidencySearchDB[]) => data.map((item) => residencySearchConverter.fromDb(item)),
    }),
  }),
  overrideExisting: false,
});

export const { useGetResidenciesSearchQuery } = residencyApi;
