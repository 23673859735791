export enum EText {
  DEFAULT_ERROR = 'Something went wrong',
}

export enum EGenderUI {
  FEMALE = 'Female',
  MALE = 'Male',
}
export enum EGenderDB {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum EFileTypeUI {
  VIDEO = 'video',
  PHOTO = 'image',
  OTHER = 'other',
}

export enum EFileTypeDB {
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  OTHER = 'other',
}

export type TFileUI = {
  url: string;
  name: string;
  type: EFileTypeUI;
};

export type TFileDB = {
  url: string;
  name: string;
  type?: EFileTypeDB;
};
