import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import ReportAnimalComplaint from 'components/Base/ReportAnimalComplaint';

import { AnimalDetailS, BaseImage } from '../../../../components/Base/style';
import animalIcon from '../../../../assets/images/animalIcon.svg';
import { ImageName } from '../../style';
import ResidentInfo from './components/ResidentInfo';
import AnimalInfo from './components/AnimalInfo';
import AnimalImages from '../../AnimalImages';
import { TAnimalDetailProps } from './AnimalDetail.types';

const AnimalDetail: FC<TAnimalDetailProps> = ({ data, visitation }) => {
  const { name, petPhotoUrl, imageUrls } = data;
  return (
    <AnimalDetailS>
      <ImageName>
        <BaseImage>
          <img src={petPhotoUrl || animalIcon} alt='' />
        </BaseImage>
        <Typography component='h2' variant='h2'>
          {name}
        </Typography>
      </ImageName>
      <ResidentInfo
        rentalName={data.rentalName}
        rentalUnitNumber={data.rentalUnitNumber}
        applicationType={data.applicationType}
        tenantName={data.tenantName}
      />
      <AnimalInfo data={data} />
      <AnimalImages images={imageUrls} />
      {!visitation && <ReportAnimalComplaint animal={data} />}
    </AnimalDetailS>
  );
};

export default AnimalDetail;
