import { connectRefinementList } from 'react-instantsearch-core';

import { TRefinmentListItem } from '../../types';
import { WrappedMultiSelectFilter } from './components/WrappedMultiSelectFilter';

function MultiSelectFilter(props: TRefinmentListItem) {
  const { attribute, transformItems } = props;
  const Component = connectRefinementList((refinmentListProps) =>
    WrappedMultiSelectFilter({ ...props, ...refinmentListProps })
  );
  return <Component attribute={attribute} transformItems={transformItems} />;
}

export default MultiSelectFilter;
