import styled from 'styled-components';
import Grid from '@mui/material/Grid';

export const SLoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const SGrid = styled(Grid)`
  &.MuiGrid-root {
    width: 100%;

    @media (min-width: 600px) {
      max-width: 48%;
    }

    @media (min-width: 900px) {
      max-width: 32%;
    }
  }
`;
