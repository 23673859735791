import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import { SVideoItemWrapper } from './VideoItem.style';
import { TUploadedFileProps } from '../types';
import { VideoPlayIcon } from '../../Icons/Icons';

const VideoItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  const ref = useRef<ReactPlayer>(null);
  return (
    <SVideoItemWrapper>
      <ReactPlayer
        ref={ref}
        url={file.url}
        width={203}
        height={119}
        playing={false}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        playIcon={
          <div onClick={() => ref.current?.seekTo(0)}>
            <VideoPlayIcon />
          </div>
        }
      />
      {!readOnly && (
        <div className='close-icon' onClick={onDelete}>
          <CloseIcon />
        </div>
      )}
    </SVideoItemWrapper>
  );
};

export { VideoItem };
