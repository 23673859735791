import React from 'react';
import { FormBlock, FormContentS } from '../../styles';
import Address from '../Address';
import ComplaintInformation from '../ComplaintInformation';
import PhotoDropzone from '../../../MultiplePhotoUpload';
import { TFormContentProps } from './FormContent.types';

const FormContent = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  files,
  onFilesChange,
  hideAddressInfo,
}: TFormContentProps) => {
  return (
    <FormContentS>
      {!hideAddressInfo && (
        <Address
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      <ComplaintInformation
        handleChange={handleChange}
        value={values.title}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
      />
      <FormBlock margin='0 0 100px 0'>
        <PhotoDropzone
          className='complaint-image'
          label='Add images or videos'
          files={files}
          onChange={onFilesChange}
        />
      </FormBlock>
    </FormContentS>
  );
};

export default FormContent;
