import { TConverter, TCreateConverter } from 'data/types/converter.types';
import { fileConverter } from 'data/converters/general.converters';
import {
  EComplaintTitleDB,
  EComplaintTitleUI,
  EComplaintTypeDB,
  EComplaintTypeUI,
  TCreateComplaintDB,
  TCreateComplaintUI,
} from 'data/types/complaint.types';

export const complaintTypeConverter: TConverter<EComplaintTypeUI, EComplaintTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EComplaintTypeDB]: EComplaintTypeUI } = {
      [EComplaintTypeDB.COMPLAINT]: EComplaintTypeUI.COMPLAINT,
      [EComplaintTypeDB.VIOLATION]: EComplaintTypeUI.VIOLATION,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EComplaintTypeUI]: EComplaintTypeDB } = {
      [EComplaintTypeUI.COMPLAINT]: EComplaintTypeDB.COMPLAINT,
      [EComplaintTypeUI.VIOLATION]: EComplaintTypeDB.VIOLATION,
    };
    return dataMapper[data];
  },
};

export const complaintTitleConverter: TConverter<EComplaintTitleUI, EComplaintTitleDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EComplaintTitleDB]: EComplaintTitleUI } = {
      [EComplaintTitleDB.UNAUTHORIZED_ANIMAL]: EComplaintTitleUI.UNAUTHORIZED_ANIMAL,
      [EComplaintTitleDB.AGGRESSIVE_BEHAVIOR]: EComplaintTitleUI.AGGRESSIVE_BEHAVIOR,
      [EComplaintTitleDB.DAMAGE]: EComplaintTitleUI.DAMAGE,
      [EComplaintTitleDB.NOISE_DISTURBANCE]: EComplaintTitleUI.NOISE_DISTURBANCE,
      [EComplaintTitleDB.PET_RULE_VIOLATION]: EComplaintTitleUI.PET_RULE_VIOLATION,
      [EComplaintTitleDB.WASTE_MANAGEMENT]: EComplaintTitleUI.WASTE_MANAGEMENT,
      [EComplaintTitleDB.OTHER]: EComplaintTitleUI.OTHER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EComplaintTitleUI]: EComplaintTitleDB } = {
      [EComplaintTitleUI.UNAUTHORIZED_ANIMAL]: EComplaintTitleDB.UNAUTHORIZED_ANIMAL,
      [EComplaintTitleUI.AGGRESSIVE_BEHAVIOR]: EComplaintTitleDB.AGGRESSIVE_BEHAVIOR,
      [EComplaintTitleUI.DAMAGE]: EComplaintTitleDB.DAMAGE,
      [EComplaintTitleUI.NOISE_DISTURBANCE]: EComplaintTitleDB.NOISE_DISTURBANCE,
      [EComplaintTitleUI.PET_RULE_VIOLATION]: EComplaintTitleDB.PET_RULE_VIOLATION,
      [EComplaintTitleUI.WASTE_MANAGEMENT]: EComplaintTitleDB.WASTE_MANAGEMENT,
      [EComplaintTitleUI.OTHER]: EComplaintTitleDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const createComplaintConverter: TCreateConverter<TCreateComplaintUI, TCreateComplaintDB> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      residenceId: data.residenceId || null,
      unitName: data.unitName || null,
      type: complaintTypeConverter.toDb(data.type),
      title: complaintTitleConverter.toDb(data.title),
      description: data.description || null,
      attachments: data?.attachments.map((item) => fileConverter.toDb(item)) || [],
    };
  },
};
