import React, { useState } from 'react';
import { AutoComplete } from '@landlord-tech/opp-ui-kit';
import { useGetPropertiesSearchQuery, useGetUnitsSearchQuery } from 'redux/apiSlice/property.slices';
import { useGetResidenciesSearchQuery } from 'redux/apiSlice/residency.slices';
import { TUnitSearchUI } from 'data/types/property.types';
import { TResidencySearchUI } from 'data/types/residency.types';
import { FormBlock, FormFields } from '../../styles';
import { MediumLightGrayText } from '../../../style';
import { TAddressProps } from './Address.types';

const Address = ({ handleChange, values, errors, handleBlur, touched }: TAddressProps) => {
  const [selectedUnit, setSelectedUnit] = useState<TUnitSearchUI | null>(null);
  const { data: properties } = useGetPropertiesSearchQuery();
  const { data: units } = useGetUnitsSearchQuery(values.propertyId, { skip: !values.propertyId });
  const { data: residencies } = useGetResidenciesSearchQuery({
    propertyIds: values.propertyId ? [values.propertyId] : undefined,
    unitIds: selectedUnit ? [selectedUnit.unitId] : undefined,
  });

  const handlePropertyChange = (value: string) => {
    handleChange('propertyId', value);
    handleChange('residenceId', '');
    handleChange('unitName', '');
    setSelectedUnit(null);
  };

  const handleUnitChange = (value: TUnitSearchUI) => {
    handleChange('unitName', value.key);
    setSelectedUnit(value);
    handleChange('residenceId', '');
  };

  const handleResidenceChange = (value: TResidencySearchUI) => {
    handleChange('residenceId', value.key);
    handleChange('violatorName', value.name);
    const residence = residencies?.find((item) => item.key === value.key);
    const property = properties?.find((item) => item.name === residence?.propertyName);
    handleChange('unitName', residence?.unitNumber || '');
    if (property) {
      handleChange('propertyId', property.key);
    }
  };

  return (
    <>
      <FormBlock>
        <MediumLightGrayText>Address form</MediumLightGrayText>
        <FormFields>
          <AutoComplete
            name='propertyId'
            label='Property name'
            handleChange={(name: string, value: any) => handlePropertyChange(value[0].key)}
            value={values.propertyId || ''}
            placeholder='Property name'
            errorMessage={(touched.propertyId && errors.propertyId) as string}
            options={properties || []}
            multiple={false}
            handleBlur={handleBlur}
          />
        </FormFields>
      </FormBlock>
      <FormBlock margin='24px 0'>
        <MediumLightGrayText>Resident info</MediumLightGrayText>
        <FormFields>
          <AutoComplete
            name='unitName'
            label='Unit number'
            handleChange={(name: string, value: any) => handleUnitChange(value[0])}
            value={values.unitName || ''}
            placeholder='Select or type'
            errorMessage={(touched.unitName && errors.unitName) as string}
            options={units || []}
            multiple={false}
            handleBlur={handleBlur}
            className='margin'
          />
          <AutoComplete
            name='residenceId'
            label='Assignee'
            handleChange={(name: string, value: any) => handleResidenceChange(value[0])}
            value={values.residenceId}
            placeholder='Select resident'
            errorMessage={(touched.residenceId && errors.residenceId) as string}
            options={residencies || []}
            multiple={false}
            handleBlur={handleBlur}
          />
        </FormFields>
      </FormBlock>
    </>
  );
};

export default Address;
