import { authSplitApi } from 'redux/helpers/slice.helpers';
import { ENoticeUrl } from 'data/urls/notice.url';
import { TNoticeCreateUI } from 'data/types/notice.types';
import { createNoticeConvertor } from 'data/converters/notice.converters';

const baseUrl = ENoticeUrl.NOTICES;

export const noticeApi = authSplitApi('notice', [], true).injectEndpoints({
  endpoints: (build) => ({
    createNotice: build.mutation<void, TNoticeCreateUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'POST',
          body: createNoticeConvertor.toDb(body),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useCreateNoticeMutation } = noticeApi;
