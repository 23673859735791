import { TFileDB, TFileUI } from 'data/types/general.types';

export enum EComplaintTitleUI {
  UNAUTHORIZED_ANIMAL = 'Unauthorized animal',
  DAMAGE = 'Damage',
  NOISE_DISTURBANCE = 'Noise disturbance',
  WASTE_MANAGEMENT = 'Waste management',
  PET_RULE_VIOLATION = 'Pet rule violation',
  AGGRESSIVE_BEHAVIOR = 'Aggressive Behavior',
  OTHER = 'Other',
}
export enum EComplaintTitleDB {
  UNAUTHORIZED_ANIMAL = 'UNAUTHORIZED_ANIMAL',
  DAMAGE = 'DAMAGE',
  NOISE_DISTURBANCE = 'NOISE_DISTURBANCE',
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  PET_RULE_VIOLATION = 'PET_RULE_VIOLATION',
  AGGRESSIVE_BEHAVIOR = 'AGGRESSIVE_BEHAVIOR',
  OTHER = 'OTHER',
}

export enum EComplaintTypeUI {
  COMPLAINT = 'Complaint',
  VIOLATION = 'Violation',
}
export enum EComplaintTypeDB {
  COMPLAINT = 'COMPLAINT',
  VIOLATION = 'VIOLATION',
}

export type TCreateComplaintDB = {
  propertyId: string;
  residenceId?: string | null;
  unitName?: string | null;
  type: EComplaintTypeDB;
  title: EComplaintTitleDB;
  description: string | null;
  attachments: TFileDB[];
};

export type TCreateComplaintUI = {
  propertyId: string;
  residenceId?: string;
  unitName: string;
  type: EComplaintTypeUI;
  title: EComplaintTitleUI;
  description: string;
  attachments: TFileUI[];
};
