import styled from 'styled-components';

export const SVideoItemWrapper = styled.div`
  width: 203px;
  height: 119px;
  position: relative;

  .close-icon {
    position: absolute;
    right: -8px;
    top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9e9e9e;
    background-color: #fff;
    border-radius: 50%;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
