import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

const baseUrl = `${process.env.REACT_APP_HOSTNAME}/api/v1`;
const baseUrlV2 = `${process.env.REACT_APP_HOSTNAME_V2}/maintainer/api/v2`;

export const authSplitApi = (reducerPath: string, tagTypes?: string[], newBackend?: boolean) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: newBackend ? baseUrlV2 : baseUrl,
      prepareHeaders: async (headers) => {
        const session = await Auth.currentSession();
        if (session) {
          headers.set('authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    endpoints: () => ({}),
  });
