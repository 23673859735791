import React from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@landlord-tech/opp-ui-kit';

import { Hit, BaseHit } from 'instantsearch.js';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import { THitsProps, TInfiniteHitsProvided } from 'components/Base/Algolia/types';
import { SGrid, SLoadMoreWrapper } from './style';

function WrappedInfiniteHits<THit>({
  hits,
  hasMore,
  refineNext,
  hitComponent: HitComponent,
}: TInfiniteHitsProvided<THit>) {
  return (
    <>
      <Grid container gap={2}>
        {hits.map((item, index) => (
          <SGrid item xs={12} sm={6} md={4} key={`hit-${index}`}>
            <>{HitComponent(item as Hit<THit & BaseHit>)}</>
          </SGrid>
        ))}
      </Grid>
      {hasMore && (
        <SLoadMoreWrapper>
          <Button text='Load More' onClick={refineNext} />
        </SLoadMoreWrapper>
      )}
    </>
  );
}

function InfiniteHits<Thit>(props: THitsProps<Thit>) {
  const Component = connectInfiniteHits((infiniteHitsProps) => WrappedInfiniteHits({ ...props, ...infiniteHitsProps }));
  return <Component />;
}

export default InfiniteHits;
