import { useHttpRequest } from '../shared/HttpRequest';
import {forgotPasswordCasesConverter} from "../data/converters/auth.converters";
import {EForgotPasswordCasesUI} from "../data/types/auth.types";

export const useResetPassword = (onSuccess?: (data: EForgotPasswordCasesUI ) => void) => {
  const response = useHttpRequest<any, void, EForgotPasswordCasesUI>({
    url: `/profile/forgot-password`,
    method: 'post',
    onSuccess,
    convertor: forgotPasswordCasesConverter.fromDb,
    newBackend: true,

  });
  return response;
};
