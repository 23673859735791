import { TFetchConverter } from '../types/converter.types';
import { EForgotPasswordCasesDB, EForgotPasswordCasesUI } from '../types/auth.types';

export const forgotPasswordCasesConverter: TFetchConverter<EForgotPasswordCasesUI, EForgotPasswordCasesDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EForgotPasswordCasesDB]: EForgotPasswordCasesUI } = {
      [EForgotPasswordCasesDB.PASSWORD_RESET_CODE_DELIVERED]: EForgotPasswordCasesUI.RESET_PASSWORD,
      [EForgotPasswordCasesDB.INVITATION_RESEND]: EForgotPasswordCasesUI.RESEND_INVITATION,
    };
    return dataMapper[data];
  },
};
