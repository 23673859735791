import React, { useState } from 'react';
import get from 'lodash/get';

import { Button, Input } from '@landlord-tech/opp-ui-kit';
import { UseForm } from 'components/useForm';
import { ArrowTopIcon, PhotoEditIcon } from 'components/Base/Icons/Icons';
import { useAppSelector } from 'redux/store';
import { useUpdateProfileNameMutation, useUpdateProfilePhotoMutation } from 'redux/apiSlice/profile.slices';
import { TRtkErrorType } from 'data/types/api.types';
import { usePhotoUpload } from 'Hooks/uploadFile';
import { useAppDispatch } from 'contexts/store';
import profileIcon from 'assets/images/profileIcon.svg';

import { Upload } from 'shared/file';
import { InputField } from 'pages/Login/styles';
import { EText } from 'data/types/general.types';
import { validationSchema } from './validationSchema';
import {
  BackToSettings,
  EditBtns,
  PhotoChange,
  PhotoEdit,
  PhotoEditInput,
  ProfileImage,
  SettingsPageName,
} from '../../styles';

type PhotoUploadProps = {
  handleLoading: (val: boolean) => void;
  handleEditableInfo: (val: string) => void;
};

const PhotoUpload = ({ handleLoading, handleEditableInfo }: PhotoUploadProps) => {
  const dispatch = useAppDispatch();
  const [updatedPhotoUrl, setUpdatedPhotoUrl] = useState('');

  const { user: profile } = useAppSelector((state) => ({
    user: state.authSlice.user,
  }));

  const { reFetch } = usePhotoUpload(async (photoUrl) => {
    setUpdatedPhotoUrl(photoUrl);
  });

  const [updatePhoto, { isLoading: loadingPhoto }] = useUpdateProfilePhotoMutation();
  const [updateName, { isLoading: loadingName }] = useUpdateProfileNameMutation();

  const handleUploadClick = async (event: any) => {
    handleLoading(true);
    const selectedFile = event.target.files[0];
    const formData = Upload({ file: selectedFile });

    await reFetch({ body: formData });
    handleLoading(false);
  };

  const handleSubmit = async (values: { name: string }) => {
    if (!profile) {
      return;
    }

    try {
      if (updatePhoto) {
        await updatePhoto({ photoUrl: updatedPhotoUrl || profile?.photoUrl }).unwrap();
      }
      if (values.name !== profile?.fullName) {
        await updateName({ fullName: values.name || profile?.fullName }).unwrap();
      }
      handleEditableInfo('');
    } catch (e) {
      if (!(e as TRtkErrorType)?.data) {
        const message = get(e, 'data.message', EText.DEFAULT_ERROR);
        dispatch({
          type: 'OPEN_ALERT',
          payload: {
            severity: 'error',
            message,
          },
        });
      }
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit: handleFormSubmit,
    handleChange,
    handleBlur,
  } = UseForm({
    initialValues: { name: profile?.fullName },
    onSubmit: (val: { name: string }) => handleSubmit(val),
    validationSchema,
    validateOnChange: true,
  });

  return (
    <PhotoEdit>
      <BackToSettings onClick={() => handleEditableInfo('')}>
        <ArrowTopIcon color='#5F5F5F' />
        <SettingsPageName>Profile settings</SettingsPageName>
      </BackToSettings>
      <form id='update-profile' onSubmit={handleFormSubmit}>
        <ProfileImage>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='contained-button-profile'>
            <img src={updatedPhotoUrl || profile?.photoUrl || profileIcon} alt='' />
          </label>
          <PhotoEditInput>
            <input accept='.jpg,.jpeg,.png' id='contained-button-profile' type='file' onChange={handleUploadClick} />
          </PhotoEditInput>
          <PhotoChange>
            <PhotoEditIcon />
          </PhotoChange>
        </ProfileImage>
        <InputField>
          <Input
            type='text'
            name='name'
            value={values.name}
            placeholder='Name'
            label='Full name'
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={(touched?.name && errors?.name) as string}
          />
        </InputField>
        <EditBtns>
          <Button className='cancel-btn' text='Cancel' onClick={() => handleEditableInfo('')} />
          <Button
            type='submit'
            text='Save'
            form='update-profile'
            disabled={
              (!updatedPhotoUrl && (!values.name || values.name === profile?.fullName)) || loadingName || loadingPhoto
            }
          />
        </EditBtns>
      </form>
    </PhotoEdit>
  );
};

export default PhotoUpload;
