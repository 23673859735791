import { TFetchConverter } from 'data/types/converter.types';
import { TPropertySearchDB, TPropertySearchUI, TUnitSearchDB, TUnitSearchUI } from 'data/types/property.types';

export const propertySearchConverter: TFetchConverter<TPropertySearchUI, TPropertySearchDB> = {
  fromDb: (data) => ({
    name: data.propertyName,
    key: data.propertyId,
  }),
};

export const unitSearchConverter: TFetchConverter<TUnitSearchUI, TUnitSearchDB> = {
  fromDb: (data) => ({
    unitId: data.unitId,
    name: data.unitName,
    key: data.unitName,
  }),
};
