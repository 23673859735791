import { EGenderDB, EGenderUI } from 'data/types/general.types';

export enum EAnimalTypeUI {
  PET = 'Pet',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAnimalTypeDB {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export type TWeightDB = {
  value: number;
  unit: string;
};

export type TAgeDB = {
  animalAge: number;
  animalAgeUnit: string;
};

export type TAnimalUI = {
  name: string;
  type: string;
  tenantName: string;
  rentalName: string;
  petPhotoUrl: string;
  color: string[];
  imageUrls: string[];
  breed: string;
  gender: EGenderUI;
  ageUnit: string;
  rentalId: string;
  applicationType: EAnimalTypeUI;
  rentalUnitNumber: string;
  age: number;
  weight: number;
  expirationDate: string;
  houseTrained: string;
  residenceId: string;
};
export type TAnimalDB = {
  id: string;
  type: EAnimalTypeDB;
  name: string;
  profilePicture: string;
  propertyUnitNumber: string;
  propertyName: string;
  propertyAddress: string;
  propertyId: string;
  tenantName: string;
  breed: string;
  species: string;
  colors: string[];
  photos: string[];
  age: TAgeDB;
  gender: EGenderDB;
  weight: TWeightDB;
  rabiesVaccinationExpiration: string;
  houseTrained: boolean;
  leaseStartDate: string;
  status: string;
  animalResidencyId: string;
  residenceId: string;
};
