import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { connectSearchBox } from 'react-instantsearch-dom';

import { TSearchBoxProps } from '../types';
import { SIconButton, SSearch, SSearchIcon, STextField } from '../style';

export const SearchBox = connectSearchBox(
  ({ className, placeholder, currentRefinement, isSearchStalled, refine }: TSearchBoxProps): JSX.Element => {
    return (
      <SSearch>
        <STextField
          fullWidth
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
          className={className}
          placeholder={placeholder}
        />
        <SSearchIcon>
          <SearchIcon />
        </SSearchIcon>
        {(!!currentRefinement.length || isSearchStalled) && (
          <SIconButton onClick={() => refine('')}>
            <ClearIcon />
          </SIconButton>
        )}
      </SSearch>
    );
  }
);
