import { TCreateConverter } from 'data/types/converter.types';
import { TNoticeCreateUI, TNoticeCreateDB } from 'data/types/notice.types';
import { fileConverter } from 'data/converters/general.converters';
import { complaintTitleConverter } from 'data/converters/complaint.converters';

export const createNoticeConvertor: TCreateConverter<TNoticeCreateUI, TNoticeCreateDB> = {
  toDb: (noticeData) => ({
    description: noticeData.description,
    propertyId: noticeData.propertyId,
    unitName: noticeData.unitName || null,
    violatorName: noticeData.violatorName || null,
    attachments: (noticeData.attachments || []).map((item) => fileConverter.toDb(item)) || [],
    title: complaintTitleConverter.toDb(noticeData.title),
  }),
};
