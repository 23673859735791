import { axiosNew, axiosOld } from '../../axios';

// This can be removed after all users are migrated
export const getNewUserExists = async (): Promise<{ data: boolean }> => {
  return axiosNew.get('/profile/exists');
};

export const getOldUserExists = async (): Promise<{ data: boolean }> => {
  return axiosOld.get('/maintainer/exists');
};
