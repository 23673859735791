import { TFetchConverter } from 'data/types/converter.types';
import { TResidencySearchDB, TResidencySearchUI } from 'data/types/residency.types';

export const residencySearchConverter: TFetchConverter<TResidencySearchUI, TResidencySearchDB> = {
  fromDb: (data) => ({
    ...data,
    name: data.residentName,
    key: data.residenceId,
  }),
};
