import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import MainLayout from 'components/Base/MainLayout';
import { RoutesNames } from 'routes/RoutesNames';
import useInitSearchIndex from 'Hooks/useInitSearchIndex';
import { simpleIndividualAnimalConvertor } from 'convertors';
import { useGetAnimalQuery } from 'redux/apiSlice/animal.slices';
import AnimalDetail from './components/AnimalDetail';

const AnimalView = () => {
  const history = useHistory();
  const { state } = useLocation() as { state: { visitation?: boolean } };
  const index = useInitSearchIndex();
  const { id } = useParams<{ id: string }>();
  const [animal, setAnimal] = useState<any>(null);

  const { data } = useGetAnimalQuery({ animalId: id }, { skip: !!state.visitation });
  // TODO: Add visitation animal v2 request when page view is updated

  const getAnimal = async () => {
    if (!index) {
      return;
    }

    try {
      const data = await index.getObject<DataTypesComesFromBack.IndividualAnimalRequestData>(id);
      setAnimal(simpleIndividualAnimalConvertor(data));
    } catch (e) {
      history.push(RoutesNames.animals);
    }
  };

  useEffect(() => {
    if (!index) {
      return;
    }
    getAnimal();
  }, [index]);

  if (!data && !animal) {
    return null;
  }

  return (
    <MainLayout padding='0 20px 68px 20px'>
      {animal && <AnimalDetail data={data || animal} visitation={!!state.visitation} />}
    </MainLayout>
  );
};

export default AnimalView;
