import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TUploadedFileProps } from '../types';

const DocumentItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  return (
    <div>
      <Typography variant='body1' color='textSecondary'>
        {file.name}
      </Typography>
      {!readOnly && (
        <div className='close-icon' onClick={onDelete}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export { DocumentItem };
