import { useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { useGetAnimalAlgoliaKeyQuery } from 'redux/apiSlice/animal.slices';

const useInitSearchIndex = () => {
  const [apiKey, setApiKey] = useState('');
  const { data } = useGetAnimalAlgoliaKeyQuery();

  useEffect(() => {
    if (!data) {
      return;
    }
    setApiKey(data.key);
  }, [data]);

  return useMemo(() => {
    if (!apiKey) {
      return null;
    }

    const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || '', apiKey);

    return searchClient?.initIndex(process.env.REACT_APP_ALGOLIA_APPLICATION_INDEX || '');
  }, [apiKey]);
};

export default useInitSearchIndex;
