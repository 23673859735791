import { FC } from 'react';
import { EFileTypeUI } from 'data/types/general.types';
import { VideoItem } from './VideoItem';
import { ImageItem } from './ImageItem';
import { DocumentItem } from './DocumentItem';
import { TUploadedFileProps } from './types';

export const fileTypesMapper: { [key in EFileTypeUI]: string[] } = {
  [EFileTypeUI.PHOTO]: [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/bmp',
    'image/jpg',
    'image/avif',
    'image/svg+xml',
    'image/tif',
    'image/tiff',
    'image/webp',
  ],
  [EFileTypeUI.VIDEO]: ['video/mp4', 'video/mpeg', 'video/ogv', 'video/ts', 'video/webm', 'video/MP4'],
  [EFileTypeUI.OTHER]: [],
};

export const uploadItemByFileType: { [key in EFileTypeUI]: FC<TUploadedFileProps> } = {
  [EFileTypeUI.PHOTO]: ImageItem,
  [EFileTypeUI.VIDEO]: VideoItem,
  [EFileTypeUI.OTHER]: DocumentItem,
};
