import React, { useEffect, useState } from 'react';
import Amplify from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import { useGetProfileQuery } from 'redux/apiSlice/profile.slices';
import { useForgotPasswordMutation } from 'redux/apiSlice/auth.slices';
import { getNewUserExists, getOldUserExists } from 'api/services/userExistServices';

import InnerPageAuth from '../../components/Base/InnerPageAuth';
import FormContent from './components/FormContent';
import { UseForm } from '../../components/useForm';
import { TextField, AuthReminder, ResetLink, ErrorMessage } from './styles';
import { TCredentials, TError } from './types';
import { validationSchema } from './validationSchema';
import { RoutesNames } from '../../routes/RoutesNames';
import awsExports from '../../aws-exports';
import { useTemporaryPasswordChecking, useCompleteNewPassword } from '../../Hooks/maintainerAWS';
import { userTypes, notAllowedUserLoginText } from '../../shared/enum';
import { useAppDispatch } from '../../contexts/store';

Amplify.configure(awsExports);

enum validationSchemaEnum {
  passwordChange = 'passwordChange',
  login = 'login',
}

const Login = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [cognitoUser, setCognitoUser] = useState<{ attributes: any; signInUserSession: any } | null>(null);
  const [authStep, setAuthStep] = useState(validationSchemaEnum.login);
  const [loading, setLoading] = useState(false);

  const {
    errors,
    values,
    handleBlur,
    touched,
    handleSubmit: handleFormSubmit,
    handleChange,
    resetForm,
  } = UseForm({
    initialValues: { confirmPassword: '', password: '', phoneNumber: '' },
    onSubmit: (val: any) => handleSubmit(val),
    validationSchema: validationSchema[authStep],
    validateOnChange: false,
  });

  const [refetch] = useForgotPasswordMutation();

  const {
    data,
    reFetch,
    error: passwordCheckingError,
    setError,
  } = useTemporaryPasswordChecking(
    () => {
      setLoading(false);
      resetForm();
      setAuthStep(validationSchemaEnum.passwordChange);
    },
    (err) => {
      setLoading(false);
      if (err.code === 'PasswordResetRequiredException') {
        refetch({ phone: values.phoneNumber.replace(/[- )(]/g, '') });
        history.push({
          pathname: RoutesNames.resetPassword,
          state: {
            defaultStep: 'passwordFill',
            phone: values.phoneNumber.replace(/[- )(]/g, ''),
          } as { defaultStep: string; phone: string },
        });
      }
    }
  );

  const {
    reFetch: completePassword,
    error,
    setError: setCompleteNewPasswordError,
  } = useCompleteNewPassword(() => {
    setAuthStep(validationSchemaEnum.login);
  });

  const {
    data: user,
    isSuccess,
    isLoading,
  } = useGetProfileQuery(null, {
    skip: !cognitoUser,
  });

  useEffect(() => {
    if (!isSuccess || !user) {
      return;
    }
    const { showIntro } = user;

    if (showIntro) {
      return history.push(RoutesNames.intro);
    }
    return history.push(RoutesNames.animals);
  }, [user]);

  const errorMessage = passwordCheckingError || error;

  useEffect(() => {
    if (errorMessage) {
      setError(null);
      setCompleteNewPasswordError(null);
    }
  }, [values]);

  const handleRedirection = (credentials: TCredentials) => {
    if (credentials) {
      const encodedPassword = window.btoa(credentials.password);
      localStorage.clear();
      window.open(
        `https://maintainer.ourpetpolicy.com?u=${encodeURIComponent(credentials.username)}&p=${encodeURIComponent(
          encodedPassword
        )}`,
        '_self'
      );
    }
  };

  const handleUserNotExist = () => {
    setLoading(false);
    dispatch({
      type: 'OPEN_ALERT',
      payload: {
        message: 'User does not exist',
        severity: 'error',
      },
    });
    localStorage.clear();
  };

  const logIn = async (phoneNumber: string, password: string) => {
    const phone = phoneNumber.replace(/[- )(]/g, '');
    const response = (await reFetch({ phoneNumber: phone, password })) as any;

    // Logic of new and old user check can be removed after all users are migrated
    try {
      const newUserExists = await getNewUserExists();
      if (!newUserExists?.data) {
        const oldUserExists = await getOldUserExists();

        if (oldUserExists?.data) {
          handleRedirection({ username: phone, password });
          return;
        }
        handleUserNotExist();
        return;
      }
    } catch (e) {
      handleUserNotExist();
      return;
    }

    if (response.attributes['custom:userType'] !== userTypes.maintainer) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: notAllowedUserLoginText,
          severity: 'error',
        },
      });
      return setLoading(false);
    }

    if (response) {
      setCognitoUser({
        attributes: response.attributes,
        signInUserSession: response.signInUserSession,
      });
    }
    return setLoading(false);
  };

  const handleSubmit = async (values: { phoneNumber?: string; password: string }) => {
    const { phoneNumber = '', password = '' } = values;
    setLoading(true);
    setCompleteNewPasswordError(null);
    setError(null);
    if (authStep !== validationSchemaEnum.passwordChange) {
      return logIn(phoneNumber, password);
    }
    await completePassword({ data, password });
    return setLoading(false);
  };

  const Footer = () => {
    return (
      <AuthReminder>
        <TextField>Forgot password? &nbsp;</TextField>
        <ResetLink onClick={() => history.push(RoutesNames.resetPassword)}>Reset it here</ResetLink>
      </AuthReminder>
    );
  };

  return (
    <InnerPageAuth
      loading={loading || isLoading}
      title='Welcome to the Pet Mapping Tool'
      text='Please enter the phone number associated with your Pet Mapping invite and enter a password to get started.'
      footer={<Footer />}
    >
      <form onSubmit={handleFormSubmit}>
        <FormContent
          values={values}
          touched={touched}
          authStep={authStep}
          errors={errors as TError}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </form>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InnerPageAuth>
  );
};

export default Login;
