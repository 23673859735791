import { TConverter, TFetchConverter } from 'data/types/converter.types';
import { EAnimalTypeUI, EAnimalTypeDB, TAnimalDB, TAnimalUI } from 'data/types/animal.types';
import { genderConverter } from 'data/converters/general.converters';

export const animalTypesConverter: TConverter<EAnimalTypeUI, EAnimalTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalTypeDB]: EAnimalTypeUI } = {
      [EAnimalTypeDB.PET]: EAnimalTypeUI.PET,
      [EAnimalTypeDB.ESA]: EAnimalTypeUI.ESA,
      [EAnimalTypeDB.SA]: EAnimalTypeUI.SA,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalTypeUI]: EAnimalTypeDB } = {
      [EAnimalTypeUI.PET]: EAnimalTypeDB.PET,
      [EAnimalTypeUI.ESA]: EAnimalTypeDB.ESA,
      [EAnimalTypeUI.SA]: EAnimalTypeDB.SA,
    };
    return dataMapper[data];
  },
};

export const animalDetailsConverter: TFetchConverter<TAnimalUI, TAnimalDB> = {
  fromDb: (data) => ({
    name: data.name,
    type: data.species,
    color: data.colors,
    breed: data.breed,
    gender: genderConverter.fromDb(data.gender),
    age: data.age.animalAge,
    ageUnit: data.age.animalAgeUnit,
    weight: data.weight.value,
    rentalUnitNumber: data.propertyUnitNumber,
    imageUrls: data.photos,
    rentalName: data.propertyName,
    rentalId: data.propertyId,
    petPhotoUrl: data.profilePicture,
    tenantName: data.tenantName,
    applicationType: animalTypesConverter.fromDb(data.type),
    expirationDate: data.rabiesVaccinationExpiration,
    houseTrained: data.houseTrained ? 'Yes' : 'No',
    residenceId: data.residenceId,
  }),
};
