import styled from 'styled-components';

export const SImageItemWrapper = styled.div`
  position: relative;
  img {
    object-fit: cover;
    width: 203px;
    height: 119px;
    vertical-align: middle;
  }
  .close-icon {
    position: absolute;
    right: -8px;
    top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9e9e9e;
    background-color: #fff;
    border-radius: 50%;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
