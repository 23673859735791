import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import animalIcon from 'assets/images/animalIcon.svg';
import { PropertyIcon, ResidentIcon, UnitIcon } from 'components/Base/Icons/Icons';
import { MediumLightGrayText } from 'components/Base/style';
import { TPredefinedInfoProps } from './PredefinedInfo.types';
import { ImageName, SIconText, SImage } from './PredefinedInfo.styles';
import { FormBlock } from '../../styles';

const PredefinedInfo: FC<TPredefinedInfoProps> = ({ data }) => {
  const { petPhotoUrl, name, breed, tenantName, rentalUnitNumber, rentalName } = data;

  return (
    <div>
      <FormBlock>
        <MediumLightGrayText>Animal info</MediumLightGrayText>
        <ImageName>
          <SImage>
            <img src={petPhotoUrl || animalIcon} alt='' />
          </SImage>
          <Typography variant='h5'>
            {name} {breed && `(${breed})`}
          </Typography>
        </ImageName>
      </FormBlock>
      <FormBlock>
        <MediumLightGrayText>Resident info</MediumLightGrayText>
        <SIconText>
          <ResidentIcon />
          <Typography variant='h5'>{tenantName}</Typography>
        </SIconText>
        <SIconText>
          <PropertyIcon />
          <Typography variant='h5'>{rentalName}</Typography>
        </SIconText>
        <SIconText>
          <UnitIcon />
          <Typography variant='h5'>{rentalUnitNumber}</Typography>
        </SIconText>
      </FormBlock>
    </div>
  );
};

export default PredefinedInfo;
