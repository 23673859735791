import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EProfileUrl } from 'data/urls/profile.url';
import { TNameUpdateUI, TPhotoUpdateUI, TProfileDB, TProfileUI } from 'data/types/profile.types';
import { nameUpdateConverter, photoUpdateConverter, profileGetConverter } from 'data/converters/profile.converters';

const baseUrl = `${EProfileUrl.PROFILE}`;

export const profileApi = authSplitApi('profile', ['profile'], true).injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<TProfileUI, null>({
      query() {
        return {
          url: baseUrl,
          method: 'GET',
        };
      },
      providesTags: ['profile'],
      transformResponse: (data: TProfileDB) => profileGetConverter.fromDb(data),
    }),
    updateProfilePhoto: build.mutation<void, TPhotoUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EProfileUrl.PHOTO}`,
          method: 'PATCH',
          body: photoUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['profile'],
    }),
    updateProfileName: build.mutation<void, TNameUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EProfileUrl.NAME}`,
          method: 'PATCH',
          body: nameUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['profile'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProfileQuery, useUpdateProfilePhotoMutation, useUpdateProfileNameMutation } = profileApi;
