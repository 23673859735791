import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EAnimalUrl } from 'data/urls/animal.url';
import { TAnimalDB, TAnimalUI } from 'data/types/animal.types';
import { animalDetailsConverter } from 'data/converters/animal.converters';

const baseUrl = EAnimalUrl.ANIMALS;

export const AnimalApi = authSplitApi(
  'application',
  ['animal', 'visitationAnimal', 'algoliaKey'],
  true
).injectEndpoints({
  endpoints: (build) => ({
    getAnimal: build.query<TAnimalUI, { animalId: string }>({
      query({ animalId }) {
        return {
          url: `${baseUrl}/${animalId}`,
          method: 'GET',
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalDB) => animalDetailsConverter.fromDb(data),
    }),
    getVisitationAnimal: build.query<TAnimalUI, { animalId: string }>({
      query({ animalId }) {
        return {
          url: `${baseUrl}/${EAnimalUrl.VISITATION}/${animalId}`,
          method: 'GET',
        };
      },
      providesTags: ['visitationAnimal'],
      transformResponse: (data: TAnimalDB) => animalDetailsConverter.fromDb(data),
    }),
    getAnimalAlgoliaKey: build.query<{ key: string }, void>({
      query() {
        return {
          url: `${EAnimalUrl.SEARCH}/${EAnimalUrl.KEY}/${EAnimalUrl.ANIMAL}`,
          method: 'GET',
        };
      },
      providesTags: ['algoliaKey'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnimalQuery, useGetVisitationAnimalQuery, useGetAnimalAlgoliaKeyQuery } = AnimalApi;
