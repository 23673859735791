import { Hit } from 'instantsearch.js';
import { isArray, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import unitIcon from 'assets/images/unit.svg';
import animalIcon from 'assets/images/animalIcon.svg';
import residentIcon from 'assets/images/resident.svg';
import propertyIcon from 'assets/images/property.svg';

import { CardImage } from 'components/Base/style';
import Highlight from 'components/Base/Algolia/components/Highlight';
import Snippet from 'components/Base/Algolia/components/Snippet';

import { AnimalsContentTypes } from 'types/types';
import { RoutesNames } from 'routes/RoutesNames';

import { IndividualItemS, InfoField, AnimalCharacteristics, InfoWrapper, SIconInfo } from './style';

type TIndividualAnimalProps = { item: Hit<AnimalsContentTypes>; unitAnimal?: boolean };

const IndividualAnimal = ({ item, unitAnimal }: TIndividualAnimalProps): JSX.Element => {
  const history = useHistory();
  const { petPhotoUrl, objectID, propertyId, rentalUnitNumber, tenantName, visitation } = item;

  const handleCardClick = () => {
    history.push({
      pathname: `${RoutesNames.animals}/${objectID}`,
      state: { unitNumber: rentalUnitNumber, propertyId, visitation },
    });
  };

  return (
    <IndividualItemS flexDirection='row' padding='0' onClick={handleCardClick}>
      <CardImage>
        <img src={petPhotoUrl || animalIcon} alt='Pet' />
      </CardImage>
      <InfoWrapper>
        <Typography className='info-text' variant='h5'>
          <Highlight hit={item} attribute='animalBreed' /> (<Highlight hit={item} attribute='name' />)
        </Typography>
        <InfoField width='100%'>
          <AnimalCharacteristics>
            {(['animalColors'] as (keyof AnimalsContentTypes)[]).reduce((acc, attribute, index, raw) => {
              if (isEmpty(item[attribute])) {
                return acc;
              }
              acc.push(
                <>
                  {isArray(item[attribute]) ? (
                    <Snippet hit={item} attribute={attribute} />
                  ) : (
                    <Highlight hit={item} attribute={attribute} />
                  )}
                  {index + 1 !== raw.length && ', '}
                </>
              );
              return acc;
            }, [] as JSX.Element[])}
          </AnimalCharacteristics>
        </InfoField>
        {!!tenantName && (
          <SIconInfo>
            <img src={residentIcon || animalIcon} alt='Unit' width={14} />
            <Typography variant='h6'>
              <Highlight hit={item} attribute='tenantName' />
            </Typography>
          </SIconInfo>
        )}
        {!unitAnimal && (
          <>
            <SIconInfo>
              <img src={propertyIcon || animalIcon} alt='Resident' width={14} />
              <Typography variant='h6'>
                <Highlight hit={item} attribute='rentalName' />
              </Typography>
            </SIconInfo>
            <SIconInfo>
              <img src={unitIcon || animalIcon} alt='Resident' width={14} />
              <Typography variant='h6'>
                <Highlight hit={item} attribute='rentalUnitNumber' />
              </Typography>
            </SIconInfo>
          </>
        )}
      </InfoWrapper>
    </IndividualItemS>
  );
};

export default IndividualAnimal;
