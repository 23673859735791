import { EFileTypeDB, EFileTypeUI, EGenderDB, EGenderUI, TFileDB, TFileUI } from 'data/types/general.types';
import { TConverter } from 'data/types/converter.types';

export const fileTypeConverter: TConverter<EFileTypeUI, EFileTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EFileTypeDB]: EFileTypeUI } = {
      [EFileTypeDB.PHOTO]: EFileTypeUI.PHOTO,
      [EFileTypeDB.VIDEO]: EFileTypeUI.VIDEO,
      [EFileTypeDB.OTHER]: EFileTypeUI.OTHER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EFileTypeUI]: EFileTypeDB } = {
      [EFileTypeUI.PHOTO]: EFileTypeDB.PHOTO,
      [EFileTypeUI.VIDEO]: EFileTypeDB.VIDEO,
      [EFileTypeUI.OTHER]: EFileTypeDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const fileConverter: TConverter<TFileUI, TFileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      url: data.url,
      type: data.type ? fileTypeConverter.fromDb(data.type) : EFileTypeUI.OTHER,
    };
  },
  toDb: (data) => {
    return {
      name: data.name,
      url: data.url,
      ...(data.type && { type: fileTypeConverter.toDb(data.type) }),
    };
  },
};

export const genderConverter: TConverter<EGenderUI, EGenderDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EGenderDB]: EGenderUI } = {
      [EGenderDB.MALE]: EGenderUI.MALE,
      [EGenderDB.FEMALE]: EGenderUI.FEMALE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EGenderUI]: EGenderDB } = {
      [EGenderUI.MALE]: EGenderDB.MALE,
      [EGenderUI.FEMALE]: EGenderDB.FEMALE,
    };
    return dataMapper[data];
  },
};
