import React, { useEffect, useState } from 'react';

import InfiniteHits from 'components/Base/Algolia/components/InfiniteHits';
import { SearchBox } from 'components/Base/Algolia/components/SearchBox';
import ReportAnimalComplaint from 'components/Base/ReportAnimalComplaint';
import AlgoliaProvider from 'components/Base/Algolia';

import MainLayout from 'components/Base/MainLayout';
import AccordionList from 'components/Base/AccordionList';

import { AnimalsContentTypes } from 'types/types';

import { useGetAnimalAlgoliaKeyQuery } from 'redux/apiSlice/animal.slices';

import Animal from './components/Animal';
import { useGetRefinementList } from './components/useGetRefinementList';

const Animals = (): JSX.Element => {
  const [apiKey, setApiKey] = useState('');
  const indexName = process.env.REACT_APP_ALGOLIA_APPLICATION_INDEX;
  const configs = {
    hitsPerPage: 5,
    typoTolerance: false,
    attributesToHighlight: [
      'name',
      'rentalName',
      'tenantName',
      'animalType',
      'animalBreed',
      'applicationType',
      'rentalUnitNumber',
    ],
    attributesToSnippet: ['animalColors'],
  };

  const { refetch, data } = useGetAnimalAlgoliaKeyQuery();
  const { list: refinementList } = useGetRefinementList();

  useEffect(() => {
    if (!data) {
      return;
    }
    setApiKey(data.key);
  }, [data]);

  return (
    <MainLayout padding='0 20px 64px 20px'>
      <AlgoliaProvider
        apiKey={apiKey}
        refreshApiKey={refetch}
        indexName={indexName!}
        configs={configs}
        refinementListTitle='Animal Filters'
        refinementListTransform={() => <AccordionList list={refinementList} />}
      >
        <>
          <SearchBox placeholder='Search for something' />
          <InfiniteHits<AnimalsContentTypes> hitComponent={(data) => <Animal item={data} />} />
        </>
      </AlgoliaProvider>
      <ReportAnimalComplaint />
    </MainLayout>
  );
};

export default Animals;
