import styled from 'styled-components';
import { theme } from 'components/theme';

const Theme = theme as { [key: string]: any };

export const ImageName = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-top: 16px;
`;

export const SImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SIconText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 16px 0;

  .MuiTypography-root {
    font-weight: 500;
    color: ${Theme.secondGray};
  }
`;
