import React, { memo, FC } from 'react';

type SvgProps = {
  width: number;
  height: number;
  viewBox: string;
};

export type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const SvgIcon: FC<SvgProps> = memo(({ width, height, viewBox, children }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
    {children}
  </svg>
));

export const ViewIcon: FC<IconProps> = ({ color = '#FCC425', width = 14, height = 9 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 16 9'>
    <path
      d='M8.52659 0.306572C8.26659 0.566572 8.26659 0.986572 8.52659 1.24657L11.1133 3.83324H0.999919C0.633252 3.83324 0.333252 4.13324 0.333252 4.49991C0.333252 4.86657 0.633252 5.16657 0.999919 5.16657H11.1199L8.53325 7.75324C8.27325 8.01324 8.27325 8.43324 8.53325 8.69324C8.79325 8.95324 9.21325 8.95324 9.47325 8.69324L13.1999 4.96657C13.4599 4.70657 13.4599 4.28657 13.1999 4.02657L9.46659 0.306572C9.21325 0.0465723 8.78659 0.0465723 8.52659 0.306572Z'
      fill={color}
    />
  </SvgIcon>
);

export const CloseIcon: FC<IconProps> = ({ color = '#FCC425', width = 20, height = 20 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 20 20'>
    <path
      d='M11.89 6.7L10 8.59L8.11 6.7C7.72 6.31 7.09 6.31 6.7 6.7C6.31 7.09 6.31 7.72 6.7 8.11L8.59 10L6.7 11.89C6.31 12.28 6.31 12.91 6.7 13.3C7.09 13.69 7.72 13.69 8.11 13.3L10 11.41L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L11.41 10L13.3 8.11C13.69 7.72 13.69 7.09 13.3 6.7C12.91 6.32 12.27 6.32 11.89 6.7ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
      fill={color}
    />
  </SvgIcon>
);

export const PhotoEditIcon: FC<IconProps> = ({ color = '#0D0D0D', width = 16, height = 16 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 16 16'>
    <path
      d='M2 5.33341C2 5.70008 2.3 6.00008 2.66667 6.00008C3.03333 6.00008 3.33333 5.70008 3.33333 5.33341V4.00008H4.66667C5.03333 4.00008 5.33333 3.70008 5.33333 3.33341C5.33333 2.96675 5.03333 2.66675 4.66667 2.66675H3.33333V1.33341C3.33333 0.966748 3.03333 0.666748 2.66667 0.666748C2.3 0.666748 2 0.966748 2 1.33341V2.66675H0.666667C0.3 2.66675 0 2.96675 0 3.33341C0 3.70008 0.3 4.00008 0.666667 4.00008H2V5.33341Z'
      fill={color}
    />
    <path
      d='M8.6665 11.3333C9.77107 11.3333 10.6665 10.4378 10.6665 9.33325C10.6665 8.22868 9.77107 7.33325 8.6665 7.33325C7.56193 7.33325 6.6665 8.22868 6.6665 9.33325C6.6665 10.4378 7.56193 11.3333 8.6665 11.3333Z'
      fill={color}
    />
    <path
      d='M14 4.00008H11.8867L11.06 3.10008C10.8133 2.82675 10.4533 2.66675 10.08 2.66675H5.81333C5.92667 2.86675 6 3.08675 6 3.33341C6 4.06675 5.4 4.66675 4.66667 4.66675H4V5.33341C4 6.06675 3.4 6.66675 2.66667 6.66675C2.42 6.66675 2.2 6.59342 2 6.48008V13.3334C2 14.0667 2.6 14.6667 3.33333 14.6667H14C14.7333 14.6667 15.3333 14.0667 15.3333 13.3334V5.33341C15.3333 4.60008 14.7333 4.00008 14 4.00008ZM8.66667 12.6667C6.82667 12.6667 5.33333 11.1734 5.33333 9.33341C5.33333 7.49341 6.82667 6.00008 8.66667 6.00008C10.5067 6.00008 12 7.49341 12 9.33341C12 11.1734 10.5067 12.6667 8.66667 12.6667Z'
      fill={color}
    />
  </SvgIcon>
);

export const LogOutIcon: FC<IconProps> = ({ color = '#EA001B', width = 18, height = 18 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 18 18'>
    <path
      d='M2 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H8C8.55 18 9 17.55 9 17C9 16.45 8.55 16 8 16H2V2Z'
      fill={color}
    />
    <path
      d='M17.65 8.65003L14.86 5.86003C14.54 5.54003 14 5.76003 14 6.21003V8.00003H7C6.45 8.00003 6 8.45003 6 9.00003C6 9.55003 6.45 10 7 10H14V11.79C14 12.24 14.54 12.46 14.85 12.14L17.64 9.35003C17.84 9.16003 17.84 8.84003 17.65 8.65003Z'
      fill={color}
    />
  </SvgIcon>
);

export const WarningIcon: FC<IconProps> = ({ color = '#F6911A', width = 26, height = 23 }) => (
  <svg width={width} height={height} viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.9094 0.782162C10.9492 0.206252 12.2472 0.540892 12.885 1.5224L12.9678 1.66023L20.7268 15.6685C20.9116 16.0021 21.0085 16.3773 21.0085 16.7587C21.0085 17.9495 20.0834 18.9243 18.9126 19.0035L18.7585 19.0087H3.24278C2.86147 19.0087 2.48642 18.9118 2.15283 18.7271C1.11299 18.1513 0.707952 16.8734 1.20151 15.812L1.27441 15.6687L9.0312 1.66046C9.2357 1.2911 9.5401 0.986742 9.9094 0.782162ZM19.4146 16.3953L11.6557 2.38702C11.455 2.02467 10.9985 1.89363 10.6362 2.09433C10.5439 2.14547 10.4637 2.21533 10.4006 2.299L10.3435 2.3871L2.58666 16.3954C2.386 16.7577 2.5171 17.2141 2.87947 17.4148C2.96286 17.461 3.05403 17.4907 3.14803 17.5027L3.24278 17.5087H18.7585C19.1727 17.5087 19.5085 17.1729 19.5085 16.7587C19.5085 16.6633 19.4904 16.5691 19.4553 16.4811L19.4146 16.3953L11.6557 2.38702L19.4146 16.3953ZM11.0005 14.0018C11.552 14.0018 11.9991 14.4489 11.9991 15.0004C11.9991 15.552 11.552 15.9991 11.0005 15.9991C10.4489 15.9991 10.0018 15.552 10.0018 15.0004C10.0018 14.4489 10.4489 14.0018 11.0005 14.0018ZM10.9963 6.49955C11.3759 6.49925 11.69 6.78115 11.7399 7.14718L11.7469 7.24895L11.7505 11.7505C11.7508 12.1648 11.4153 12.5008 11.0011 12.5011C10.6214 12.5014 10.3073 12.2195 10.2574 11.8535L10.2505 11.7517L10.2469 7.25015C10.2465 6.83594 10.582 6.49988 10.9963 6.49955Z'
      fill={color}
    />
  </svg>
);

export const ArrowWarningIcon: FC<IconProps> = ({ color = '#F6911A', width = 26, height = 23 }) => (
  <svg width={width} height={height} viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.9094 0.782162C10.9492 0.206252 12.2472 0.540892 12.885 1.5224L12.9678 1.66023L20.7268 15.6685C20.9116 16.0021 21.0085 16.3773 21.0085 16.7587C21.0085 17.9495 20.0834 18.9243 18.9126 19.0035L18.7585 19.0087H3.24278C2.86147 19.0087 2.48642 18.9118 2.15283 18.7271C1.11299 18.1513 0.707952 16.8734 1.20151 15.812L1.27441 15.6687L9.0312 1.66046C9.2357 1.2911 9.5401 0.986742 9.9094 0.782162ZM19.4146 16.3953L11.6557 2.38702C11.455 2.02467 10.9985 1.89363 10.6362 2.09433C10.5439 2.14547 10.4637 2.21533 10.4006 2.299L10.3435 2.3871L2.58666 16.3954C2.386 16.7577 2.5171 17.2141 2.87947 17.4148C2.96286 17.461 3.05403 17.4907 3.14803 17.5027L3.24278 17.5087H18.7585C19.1727 17.5087 19.5085 17.1729 19.5085 16.7587C19.5085 16.6633 19.4904 16.5691 19.4553 16.4811L19.4146 16.3953L11.6557 2.38702L19.4146 16.3953ZM11.0005 14.0018C11.552 14.0018 11.9991 14.4489 11.9991 15.0004C11.9991 15.552 11.552 15.9991 11.0005 15.9991C10.4489 15.9991 10.0018 15.552 10.0018 15.0004C10.0018 14.4489 10.4489 14.0018 11.0005 14.0018ZM10.9963 6.49955C11.3759 6.49925 11.69 6.78115 11.7399 7.14718L11.7469 7.24895L11.7505 11.7505C11.7508 12.1648 11.4153 12.5008 11.0011 12.5011C10.6214 12.5014 10.3073 12.2195 10.2574 11.8535L10.2505 11.7517L10.2469 7.25015C10.2465 6.83594 10.582 6.49988 10.9963 6.49955Z'
      fill={color}
    />
  </svg>
);

export const ArrowTopIcon: FC<IconProps> = ({ color = '#000000', width = 18, height = 11 }) => (
  <svg width={width} height={height} viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.93508 1.06497L1.05008 7.94997C0.465078 8.53497 0.465078 9.47997 1.05008 10.065C1.63508 10.65 2.58008 10.65 3.16508 10.065L9.00008 4.24497L14.8201 10.065C15.4051 10.65 16.3501 10.65 16.9351 10.065C17.5201 9.47997 17.5201 8.53497 16.9351 7.94997L10.0501 1.06497C9.48008 0.479971 8.52008 0.479971 7.93508 1.06497Z'
      fill={color}
    />
  </svg>
);

export const WarningFilledIcon: FC<IconProps> = ({ color = '#FF8282', width = 22, height = 20 }) => (
  <svg width={width} height={height} viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.96049 23.0009H23.0405C25.0938 23.0009 26.3738 20.7742 25.3472 19.0009L15.3072 1.65422C14.2805 -0.119115 11.7205 -0.119115 10.6938 1.65422L0.65382 19.0009C-0.372846 20.7742 0.907153 23.0009 2.96049 23.0009ZM13.0005 13.6676C12.2672 13.6676 11.6672 13.0676 11.6672 12.3342V9.66755C11.6672 8.93422 12.2672 8.33422 13.0005 8.33422C13.7338 8.33422 14.3338 8.93422 14.3338 9.66755V12.3342C14.3338 13.0676 13.7338 13.6676 13.0005 13.6676ZM14.3338 19.0009H11.6672V16.3342H14.3338V19.0009Z'
      fill={color}
    />
  </svg>
);

export const PropertiesIcon: FC<IconProps> = ({ color = '#0D0D0D', width = 22, height = 22 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 20 17'>
    <path
      d='M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z'
      fill={color}
    />
  </SvgIcon>
);

export const FilterIcon: FC<IconProps> = ({ color = '#0D0D0D', width = 16, height = 16 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 16 16'>
    <path
      d='M0.249934 1.61C2.56993 4.59 5.99993 9 5.99993 9L5.99993 14C5.99993 15.1 6.89993 16 7.99993 16C9.09993 16 9.99993 15.1 9.99993 14V9C9.99993 9 13.4299 4.59 15.7499 1.61C16.2599 0.95 15.7899 0 14.9499 0L1.03993 0C0.209934 0 -0.260066 0.95 0.249934 1.61Z'
      fill={color}
    />
  </SvgIcon>
);

export const AnimalIcon: FC<IconProps> = ({ color = '#0D0D0D', width = 22, height = 22 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 24 24'>
    <path
      d='M4.5 12C5.88071 12 7 10.8807 7 9.5C7 8.11929 5.88071 7 4.5 7C3.11929 7 2 8.11929 2 9.5C2 10.8807 3.11929 12 4.5 12Z'
      fill={color}
    />
    <path
      d='M9 8C10.3807 8 11.5 6.88071 11.5 5.5C11.5 4.11929 10.3807 3 9 3C7.61929 3 6.5 4.11929 6.5 5.5C6.5 6.88071 7.61929 8 9 8Z'
      fill={color}
    />
    <path
      d='M15 8C16.3807 8 17.5 6.88071 17.5 5.5C17.5 4.11929 16.3807 3 15 3C13.6193 3 12.5 4.11929 12.5 5.5C12.5 6.88071 13.6193 8 15 8Z'
      fill={color}
    />
    <path
      d='M19.5 12C20.8807 12 22 10.8807 22 9.5C22 8.11929 20.8807 7 19.5 7C18.1193 7 17 8.11929 17 9.5C17 10.8807 18.1193 12 19.5 12Z'
      fill={color}
    />
    <path
      d='M17.3399 14.86C16.4699 13.84 15.7399 12.97 14.8599 11.95C14.3999 11.41 13.8099 10.87 13.1099 10.63C12.9999 10.59 12.8899 10.56 12.7799 10.54C12.5299 10.5 12.2599 10.5 11.9999 10.5C11.7399 10.5 11.4699 10.5 11.2099 10.55C11.0999 10.57 10.9899 10.6 10.8799 10.64C10.1799 10.88 9.5999 11.42 9.1299 11.96C8.2599 12.98 7.5299 13.85 6.6499 14.87C5.3399 16.18 3.7299 17.63 4.0299 19.66C4.3199 20.68 5.0499 21.69 6.3599 21.98C7.0899 22.13 9.4199 21.54 11.8999 21.54H12.0799C14.5599 21.54 16.8899 22.12 17.6199 21.98C18.9299 21.69 19.6599 20.67 19.9499 19.66C20.2599 17.62 18.6499 16.17 17.3399 14.86Z'
      fill={color}
    />
  </SvgIcon>
);

export const FilterSelectedCircleIcon: FC<IconProps> = ({ color = '', width = 8, height = 8 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 8 8'>
    <circle cx='4' cy='4' r='4' fill='url(#paint0_linear_15964_69563)' />
    <defs>
      <linearGradient
        id='paint0_linear_15964_69563'
        x1='8'
        y1='6.16394'
        x2='-0.248955'
        y2='5.6792'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FCC425' />
        <stop offset='1' stopColor='#FC9925' />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export const VersionIcon: FC<IconProps> = ({ color = '', width = 25, height = 24 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 25 24'>
    <path
      d='M12.85 15.65L15.64 12.86C15.95 12.55 15.73 12.01 15.29 12.01H13.5V4C13.5 3.45 13.05 3 12.5 3C11.95 3 11.5 3.45 11.5 4V12H9.71C9.26 12 9.04 12.54 9.36 12.85L12.15 15.64C12.34 15.84 12.66 15.84 12.85 15.65ZM21.5 3H16.49C15.95 3 15.5 3.45 15.5 3.99C15.5 4.54 15.95 4.98 16.49 4.98H20.5C21.05 4.98 21.5 5.43 21.5 5.98V18.01C21.5 18.56 21.05 19.01 20.5 19.01H4.5C3.95 19.01 3.5 18.56 3.5 18.01V5.99C3.5 5.44 3.95 4.99 4.5 4.99H8.51C9.05 4.99 9.5 4.54 9.5 4C9.5 3.45 9.05 3 8.51 3H3.5C2.4 3 1.5 3.9 1.5 5V19C1.5 20.1 2.4 21 3.5 21H21.5C22.6 21 23.5 20.1 23.5 19V5C23.5 3.9 22.6 3 21.5 3Z'
      fill='#FCC425'
    />
  </SvgIcon>
);

export const ResidentIcon: FC<IconProps> = ({ color = '#5F5F5F', width = 16, height = 16 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 16 16'>
    <path
      d='M10.3334 6.33333C11.8067 6.33333 13.0001 5.14 13.0001 3.66667C13.0001 2.19333 11.8067 1 10.3334 1C8.86008 1 7.66675 2.19333 7.66675 3.66667C7.66675 5.14 8.86008 6.33333 10.3334 6.33333Z'
      fill={color}
    />
    <path
      d='M15.3333 11.3333C15.7 11.3333 16 11.0333 16 10.6667V10C16 8.22667 12.4467 7.33333 10.6667 7.33333V11.3333H15.3333Z'
      fill={color}
    />
    <path d='M1 14.0009V7.50091L5.51667 4.26758L10.0167 7.50091V14.0009H6.85V10.3176H4.18333V14.0009H1Z' fill={color} />
  </SvgIcon>
);

export const PropertyIcon: FC<IconProps> = ({ color = '#5F5F5F', width = 16, height = 12 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 16 12'>
    <path
      d='M0.666626 12V5.5L5.18329 2.26667L9.68329 5.5V12H6.51663V8.31667H3.84996V12H0.666626ZM11.6166 3.63333H12.6166V2.63333H11.6166V3.63333ZM11.6166 6.46667H12.6166V5.46667H11.6166V6.46667ZM11.6166 9.3H12.6166V8.3H11.6166V9.3ZM10.6833 12V5L6.74996 2.15V0H15.3333V12H10.6833Z'
      fill={color}
    />
  </SvgIcon>
);

export const UnitIcon: FC<IconProps> = ({ color = '#5F5F5F', width = 12, height = 12 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 12 12'>
    <path
      d='M0 12V11H1.53333V0H8.03333V0.75H10.4833V11H12V12H9.48333V1.75H8.03333V12H0ZM6.18333 6C6.18333 5.81111 6.11944 5.65278 5.99167 5.525C5.86389 5.39722 5.70556 5.33333 5.51667 5.33333C5.32778 5.33333 5.16944 5.39722 5.04167 5.525C4.91389 5.65278 4.85 5.81111 4.85 6C4.85 6.18889 4.91389 6.34722 5.04167 6.475C5.16944 6.60278 5.32778 6.66667 5.51667 6.66667C5.70556 6.66667 5.86389 6.60278 5.99167 6.475C6.11944 6.34722 6.18333 6.18889 6.18333 6Z'
      fill={color}
    />
  </SvgIcon>
);

export const VideoPlayIcon: FC<IconProps> = ({ color = '#FFFFFF', width = 24, height = 24 }) => (
  <SvgIcon width={width} height={height} viewBox='0 0 24 24'>
    <path
      d='M8.8375 16.625L16.625 11.6667L8.8375 6.70833V16.625ZM11.6667 23.3333C10.0722 23.3333 8.56528 23.0271 7.14583 22.4146C5.72639 21.8021 4.48681 20.966 3.42708 19.9063C2.36736 18.8465 1.53125 17.6069 0.91875 16.1875C0.30625 14.7681 0 13.2611 0 11.6667C0 10.0528 0.30625 8.53611 0.91875 7.11667C1.53125 5.69722 2.36736 4.4625 3.42708 3.4125C4.48681 2.3625 5.72639 1.53125 7.14583 0.91875C8.56528 0.30625 10.0722 0 11.6667 0C13.2806 0 14.7972 0.30625 16.2167 0.91875C17.6361 1.53125 18.8708 2.3625 19.9208 3.4125C20.9708 4.4625 21.8021 5.69722 22.4146 7.11667C23.0271 8.53611 23.3333 10.0528 23.3333 11.6667C23.3333 13.2611 23.0271 14.7681 22.4146 16.1875C21.8021 17.6069 20.9708 18.8465 19.9208 19.9063C18.8708 20.966 17.6361 21.8021 16.2167 22.4146C14.7972 23.0271 13.2806 23.3333 11.6667 23.3333Z'
      fill={color}
    />
  </SvgIcon>
);
