import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { theme } from 'components/theme';

const Theme = theme as { [key: string]: any };

export const ReportAnimalComplaintS = styled.div`
  padding: 85px 18px 0;
`;

export const FormContentS = styled.div`
  margin-top: 0;

  .margin {
    margin-bottom: 24px;
  }

  .error-autoComplete {
    bottom: -18px;
  }
`;

export const FormFields = styled.div`
  margin-top: 16px;
  &.resident-info {
    div {
      width: 100%;
      input {
        height: 40px;
      }
    }
  }
  .MuiOutlinedInput-root {
    min-width: 100% !important;
  }
`;

export const FormBlock = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => margin || '0 0 16px 0'};
`;

export const ReportAnimalComplaintBtn = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 1200;
`;

export const ErrorMessage = styled(Typography)<{ component: string }>`
  &.MuiTypography-root {
    color: ${`${Theme.errorColor}`};
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    bottom: -14px;
  }
`;
