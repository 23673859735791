import axios, { Method } from 'axios';
import { Auth } from 'aws-amplify';

axios.defaults.withCredentials = true;

const baseURL = `${process.env.REACT_APP_HOSTNAME}/api/v1`;
const baseURLOld = `https://backend.prod.ourpetpolicy.com/api/v1`;
const baseURLNew = `${process.env.REACT_APP_HOSTNAME_V2}/maintainer/api/v2`;

const instance = axios.create({
  baseURL,
});

const axiosOld = axios.create({
  baseURL: baseURLOld,
});

const axiosNew = axios.create({
  baseURL: baseURLNew,
  withCredentials: false,
});

const configureAxios = async (config) => {
  const res = { ...config };
  try {
    const session = await Auth.currentSession();
    const {jwtToken} = session.idToken;
    res.headers.Authorization = jwtToken ? `Bearer ${jwtToken}` : '';
  } catch (err){ delete res.headers.Authorization}
  return res;
};

instance.interceptors.request.use(configureAxios);
axiosOld.interceptors.request.use(configureAxios);
axiosNew.interceptors.request.use(configureAxios);

const axiosUnauth = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

export { axiosUnauth, axiosOld, axiosNew, Method };

export default instance;

export const formDataCreator = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};
