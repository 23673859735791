import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TUploadedFileProps } from '../types';
import { SImageItemWrapper } from './ImageItem.style';

const ImageItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  return (
    <SImageItemWrapper>
      <img src={file.url} alt={file.name} />
      {!readOnly && (
        <div className='close-icon' onClick={onDelete}>
          <CloseIcon />
        </div>
      )}
    </SImageItemWrapper>
  );
};

export { ImageItem };
